.productCategory .tbody-adj tr:nth-child(even) th, .productCategory .tbody-adj tr:nth-child(even) td  {
    background: #fff !important;
    height: 100%;
}

.productCategory .tbody-adj tr:nth-child(odd) th, .productCategory .tbody-adj tr:nth-child(odd) td  {
    background: #F3F3FC !important;
    height: 100%;
    padding-top: unset;
}

.notification-table, .makeStyles-card-126, .notification-table-container{
    border-radius: 10px !important;
}

.tbody-adj{
    border: 15px solid #fff;
}

.notification-table thead{
    border-left: 15px solid #F3F3FC;
    border-right: 15px solid #F3F3FC;
    border-bottom: 15px solid #fff;
}

.notification-table thead tr th:first-of-type{
    border-top-left-radius: 10px;
    /* padding-top: 0 !important; */
}

/* .tbody-adj tr:first-of-type td > div{
    padding-top: 0 !important;
} */

.notification-table thead tr th:last-of-type{
    border-top-right-radius: 10px;
}

.notification-table tr td:nth-child(2) > div{
    word-break: break-all;
}
.notification-table, .makeStyles-card-126, .notification-table-container{
border-radius:5px !important;
}


.Mui-selected .MuiTab-wrapper{
    font-weight: 800;
}

@media (max-width: 576px){
    .makeStyles-content-3{
        padding: 0 16px !important;
        padding-top: 30px !important;
    }

    .makeStyles-container-4{
        padding: 0px !important;
    }

    .makeStyles-button-54{
        padding-left: 0 !important;
    }

    .notification-table tr td:nth-child(2) > div{
        max-width: 145px;
    }
    
}

@media(max-width:425px)
{
    #toggleBar
    {
        padding-left: 0px !important;
    }
}

.ReactTable .actions-right
{
    text-align: left;
}