/*!

 =========================================================
 * Material Dashboard React - v1.10.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2021 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

 @font-face {
  font-family: "Muli";
  src: url("../fonts/muli/Muli.ttf");
}

@font-face {
  font-family: "Muli-Bold";
  src: url("../fonts/muli/Muli-Bold.ttf");
}
@font-face {
  font-family: "Muli-SemiBold";
  src: url("../fonts/muli/Muli-SemiBold.ttf");
}
.MuiButton-label{
  text-transform: capitalize;
  font-size: 16px;
}

.filterInput-adj{
  width: 100% !important;
}

.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

input, select, ::placeholder, th,td,div,p,label span{
  font-family: "Muli" !important;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}


.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}

html * {
  font-family: 'Muli';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: 'Muli', Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;

}

.MuiBox-root.MuiBox-root-62 h6,
h6.MuiTypography-root.MuiTypography-h6.MuiTypography-alignCenter {
  font-size: 28px;
  font-weight: 450;
  line-height: 38px;
  font-family: 'Muli';
  color: rgb(30, 30, 30);
}

.makeStyles-card-30 {
  background: unset !important;
  box-shadow: unset !important;
}

.MuiInputBase-inputn,
.MuiInputBase-root.MuiInput-root.makeStyles-selectadj-103 {
  background-color: #F3F4F6 !important;
}

.MuiInputBase-root.MuiInput-root.makeStyles-selectadj-40.MuiInputBase-fullWidth.MuiInput-fullWidth {
  background: #fff !important;
}

.MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense,
.MuiInputBase-root.MuiInput-root.makeStyles-selectadj-82.MuiInputBase-fullWidth.MuiInput-fullWidth {
  background: #fff !important;
}

.makeStyles-padding30BackGroundBoxShadow-101 {
  background-color: unset;
}

.MuiOutlinedInput-inputMarginDense,
input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense {

  border-radius: 10px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.makeStyles-buttonStyleNextPrevious-90,
button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.makeStyles-buttonStyleNextPrevious-123 {
  margin-left: 20px;
}

.makeStyles-displayFlex-155 {
  display: flex;
  justify-content: center;
}


.MuiGrid-root.makeStyles-marginBottom24-86.makeStyles-displayFlex-85.MuiGrid-container,
.MuiBox-root.MuiBox-root-109.makeStyles-boxStyle-84.makeStyles-displayFlex-85 {
  text-align: center;
  justify-content: center;
}

.makeStyles-displayFlex-91 {

  justify-content: center;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #DB0D0D !important;
  border-color: #DB0D0D !important;
}

.makeStyles-buttonStyle-49 {
  padding: 14px 30px;
}


.select__control {
  border-radius: 10px !important;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
  background: #fff !important;
}

ul.MuiList-root.MuiMenu-list.MuiList-padding {
  background: #fff;
}

/* span.MuiButton-label {
  font: normal normal bold 16px/24px Roboto ;
  text-transform: capitalize;
} */

span.MuiButton-label #ChangePasswordText {
  font: normal normal bold 16px/24px 'Muli' !important;
  text-transform: capitalize;
}

body {
  background-color: #EAEDF7;
  color: #3c4858;
}

.MuiPaper-elevation1 {
  box-shadow: unset !important;
}

.MuiPaper-root.MuiBox-root.MuiBox-root-34.removebackgroundcolorFromCard.MuiPaper-elevation1.MuiPaper-rounded {
  margin-left: auto;
  width: 585px;
  margin-right: auto;
}

.makeStyles-marginLeft30-163 {
  margin-left: 39px !important;
}

.MuiFormControlLabel-root {
  justify-content: center !important;
}

.makeStyles-buttonStyle-485 {
  justify-content: end;
}

@media screen and (min-width:1500px) {
  .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-10.MuiGrid-grid-sm-10 {
    /* margin-top: -400px; */
  }
}

@media (min-width: 600px) {
  .MuiGrid-grid-sm-2 {
    flex-grow: 0;
    max-width: 19% !important;
    flex-basis: 25.666667% !important;
  }

  .MuiGrid-grid-sm-3 {
    flex-grow: 0;
    max-width: 33% !important;
    flex-basis: 34% !important;
  }
}


blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Muli', "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #9c27b0;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #89229b;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"]>input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {

  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}

.fixed-plugin {
  font-family: 'Muli', "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li>a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu>.active>a,
.fixed-plugin .dropdown-menu>.active>a:hover,
.fixed-plugin .dropdown-menu>.active>a:focus {
  color: #777777;
  text-align: center;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.fixed-plugin .dropdown-menu li>a:hover,
.fixed-plugin .dropdown-menu li>a:focus {
  box-shadow: none;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

@media (min-width: 600px) {
  .MuiGrid-grid-sm-2 {
    flex-grow: 0;
    max-width: 19% !important;
    flex-basis: 25.666667% !important;
  }

  .MuiGrid-grid-sm-3 {
    flex-grow: 0;
    max-width: 33% !important;
    flex-basis: 34% !important;
  }
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

@media (min-width: 600px) {
  .MuiGrid-grid-sm-2 {
    flex-grow: 0;
    max-width: 19% !important;
    flex-basis: 25.666667% !important;
  }

  .MuiGrid-grid-sm-3 {
    flex-grow: 0;
    max-width: 33% !important;
    flex-basis: 34% !important;
  }
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

@media (min-width: 600px) {
  .MuiGrid-grid-sm-2 {
    flex-grow: 0;
    max-width: 19% !important;
    flex-basis: 25.666667% !important;
  }

  .MuiGrid-grid-sm-3 {
    flex-grow: 0;
    max-width: 33% !important;
    flex-basis: 34% !important;
  }
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}

.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;

  @media (min-width: 600px) {
    .MuiGrid-grid-sm-2 {
      flex-grow: 0;
      max-width: 19% !important;
      flex-basis: 25.666667% !important;
    }

    .MuiGrid-grid-sm-3 {
      flex-grow: 0;
      max-width: 33% !important;
      flex-basis: 34% !important;
    }
  }

  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: left;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}

.fixed-plugin .adjustments-line .dropdown-menu>li.adjustments-line>a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu>li>a.img-holder {
  font-size: 16px;

  @media (min-width: 600px) {
    .MuiGrid-grid-sm-2 {
      flex-grow: 0;
      max-width: 19% !important;
      flex-basis: 25.666667% !important;
    }

    .MuiGrid-grid-sm-3 {
      flex-grow: 0;
      max-width: 33% !important;
      flex-basis: 34% !important;
    }
  }

  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

.makeStyles-selectadj-116,
.makeStyles-selectadj {
  border-radius: 10px !important;
}

.MuiOutlinedInput-root,
.css-1s2u09g-control {
  border-radius: 10px !important;
}

.MuiOutlinedInput-root,
.css-1s2u09g-control #ProfileTextField {
  border-radius: 4px !important;
  color: #25233C !important;
}

.fixed-plugin .dropdown-menu>li>a.img-holder img {
  margin-top: auto;
}

.fixed-plugin .dropdown-menu>li:hover>a.img-holder,
.fixed-plugin .dropdown-menu>li:focus>a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu>.active>a.img-holder,
.fixed-plugin .dropdown-menu>.active>a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}

.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  -moz-transform: translateY(-13%);
  -o-transform: translateY(-13%);
  -ms-transform: translateY(-13%);
  transform: translateY(-13%);
  transform-origin: 0 0;
}

.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}

.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}

* {
  letter-spacing: normal !important;
}

/* vaibhav-css */

.ReactTable .rt-thead .rt-th.-cursor-pointer>div:first-of-type:after {
  opacity: 0.9;
  content: "\F0DC";
  font-weight: 700;
  position: relative;
  display: inline-block;
  bottom: 0;
  right: -7px;
  font-family: 'fontawesome';
}

.ReactTable button[disabled] {
  opacity: .5;
  cursor: not-allowed;
}

.ReactTable.-striped .odd {
  background: rgba(0, 0, 0, .03);
}

.MuiOutlinedInput-inputMarginDense,
input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense {
  background-color: #fff;
}

ul.MuiList-root.MuiMenu-list.MuiList-padding,
.css-1s2u09g-control,
.makeStyles-selectadj-244,
.makeStyles-selectadj-265,
.MuiInputBase-root.MuiInput-root.makeStyles-selectadj-329.MuiInputBase-fullWidth.MuiInput-fullWidth {
  background: #F3F4F6;
  border-radius: 10px !important;
}

*:focus {
  border-radius: 10px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #DB0D0D !important;
  border-color: #DB0D0D !important;
  border-radius: 10px !important;
}

btn-primary:hover {
  color: #DB0D0D !important;
  border: 1px solid #DB0D0D !important;
  background: #fff !important;
}

.MuiInputBase-root.MuiInput-root.makeStyles-selectadj-104.MuiInputBase-fullWidth.MuiInput-fullWidth,
.select__control.css-1s2u09g-control {
  background-color: #F3F4F6;
}

.MuiSelect-select.MuiSelect-select,
.MuiInputBase-root.MuiInput-root.makeStyles-selectadj-134,
.css-1s2u09g-control,
.css-yt9ioa-option {
  background-color: #fff !important;
}

.ReactTable .rt-tbody .rt-tr td:first-child {
  height: 50px;
  display: flex;
  align-items: center;
}

.productCategory .tbody-adj tr:nth-child(even) div {
  background: #fff !important;
}

.productCategory .tbody-adj tr:not(:first-child) th,
.productCategory .tbody-adj tr:not(:first-child) td {
  padding-top: unset;
}

button:focus {
  outline: unset !important;
}

.makeStyles-outlinedButtonStyle-239 {}

.right {
  justify-content: flex-end !important;
}

.left {
  justify-content: flex-start !important;

}

.left .msg {
  background: #eaedf7;
}

.right .msg {
  background: #eaedf759;
  border: 1px solid #6B6987;
}

.btn-lg-adj button {
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 15px
}

.notifyText {
  color: #6B6987;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.read-or-hide {
  position: absolute;
  bottom: 10px;
  left: 15px;
  color: #6259CA;
  text-transform: capitalize;
  cursor: pointer;
}

.read-or-hide-all {
  color: #6259CA;
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -51%);
  left: 50%;
  top: 50%;
}

.sidebar-adj [class*="makeStyles-blue-"]:hover,
.sidebar-adj [class*="makeStyles-blue-"]:focus {
  background-color: unset !important;
}

.sidebar-adj [class*="makeStyles-drawerPaper-"] {
  box-shadow: unset;
}

.sidebar-adj .active [class*="makeStyles-itemText-"] {
  color: #6259CA !important
}

/* .sidebar-adj [class*="makeStyles-itemLink-"] {margin: unset !important;height: 50px;padding-left: 25px !important;transition: 0s linear;} */
.sidebar-adj [class*="makeStyles-itemLink-"] {
  margin: unset !important;
  height: 70px;
  padding-left: 25px !important;
  transition: 0s linear;
  align-items: center;
  display: flex;
  justify-content: center;
}

.sidebar-adj .active [class*="makeStyles-itemIcon-"] {
  background: #6259CA;
  color: #EAEDF7 !important;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 3px;
  margin-right: 10px;
}

/* .sidebar-adj .active [class*="makeStyles-itemLink-"] {background-image: url('https://i.ibb.co/vBCZmZS/Book-A-Pro-1-1.png') !important;box-shadow: unset !important;border-radius: 25px 0 0 25px;background-position:0.03em center;height: 80px !important;align-items: center;display: flex;background-repeat: no-repeat;padding-left: 25px;background-size: cover;transition: 0s linear;background-color: unset;} */
/* .sidebar-adj .active [class*="makeStyles-itemLink-"] {background-image: url('https://i.ibb.co/vBCZmZS/Book-A-Pro-1-1.png') !important;box-shadow: unset !important;background-position:0.15em center;align-items: center;display: flex;background-repeat: no-repeat;padding-left: 25px;background-size: cover;transition: 0s linear;background-color: unset;} */
.sidebar-adj .active [class*="makeStyles-itemLink-"] {
  background-image: url('https://i.ibb.co/68czgrd/Book-A-Pro2-1.png') !important;
  box-shadow: unset !important;
  background-position: center;
  align-items: center;
  display: flex;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: 0s linear;
  background-color: #eaedf7 !important;
  width: '260px';
}

.table-adj tbody.rt-tbody {
  padding: 15px;
}

.table-adj .pagination-top {
  padding: 0 15px;
}

.table-adj.ReactTable.-striped tr.rt-tr.-odd {
  background: #ebebf98f;
}

.table-adj thead.rt-thead.-header [class*="makeStyles-formControl-"] {
  margin: unset;
}

.table-adj thead.rt-thead.-header .jss637 {
  margin: unset;
}

.table-adj thead.rt-thead.-header {
  background: #F5F5FC;
  padding: 10px;
  /* margin-top: 10px; */
}

.table-adj thead.rt-thead.-header .rt-resizable-header-content {
  font-size: 16px;
  font-weight: 800;
  color: #8E8CAC;
}

.filterInput-adj .MuiInput-formControl {
  border: 1px solid #b9b8c4;
  padding: 8px 15px;
  border-radius: 5px;
  /* max-width: 255px; */
  width: 100%;
  height: 42px;
}

.filterInput-adj .search-adj {
  position: absolute;
  right: 5%;
  /* right: 0px; */
  top: 60%;
  color: #8E8CAC;
}

.table-adj .rt-thead .rt-resizable-header:last-child .search-adj {
  display: none
}


/* Remove padding and margin from cards */

.removePaddingTopFromCard .MuiBox-root {
  padding: 0px;
}

.removePaddingTopFromCard [class*="makeStyles-card-"] {
  margin-top: 8px;
}

.removePaddingMarginTopFromCard .MuiBox-root {
  padding: 0px;
  margin-top: 0px;
}

.removebackgroundFromCard {

  background-color: unset !important;
  box-shadow: none !important;

}

.removebackgroundcolorFromCard .MuiPaper-root {
  background-color: unset !important;
  box-shadow: none !important;
}


/* Bookings Table */
.bookingTable .table-adj .pagination-top {
  display: none
}

.bookingTable .table-adj thead.rt-thead.-header {
  margin-top: 0
}

@media screen and (max-width:767px) {
  .ReactTable .rt-table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }
}

/* Production Sidebar css */
.filterInput-adj .MuiFormControl-fullWidth {
  margin: unset;
}

/* .sidebar-adj .jss83:hover,
.sidebar-adj .jss83:focus{background-color: unset !important;} */
/* .sidebar-adj .active .jss76 {background-image: url('https://i.ibb.co/68czgrd/Book-A-Pro2-1.png') !important;box-shadow: unset !important;background-position: center;align-items: center;display: flex;background-repeat: no-repeat;background-size: 100%;transition: 0s linear;background-color: #eaedf7 !important;} */
/* .sidebar-adj .jss76 {margin: unset !important;height: 70px;padding-left: 25px !important;transition: 0s linear;align-items: center;display: flex;justify-content: center;} */
/* .sidebar-adj .active .jss76 svg{background: #6259CA;color: #EAEDF7 !important;border-radius: 50%;height: 30px;width: 30px;padding: 3px;margin-right: 10px;} */
/* .sidebar-adj .active .jss76 .jss79 {color: #6259CA !important;} */
.sidebar-adj .jss66 {
  box-shadow: unset
}


.sidebar-adj .ListItem:hover,
.sidebar-adj .ListItem:focus {
  background-color: unset !important;
}

.sidebar-adj .active .ListItem {
  background-image: url(https://i.ibb.co/68czgrd/Book-A-Pro2-1.png) !important;
  box-shadow: unset !important;
  background-position: center;
  align-items: center;
  display: flex;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: 0s linear;
  background-color: #EAEDF7 !important;
  padding-left: 16px !important;
  padding-top: 12px;
  padding-right: 10px !important;
  width: 260px;
}

.sidebar-adj .ListItem {
  margin: unset !important;
  height: 70px;
  padding-left: 25px !important;
  transition: 0s linear;
  align-items: center;
  display: flex;
  justify-content: center;
}

.sidebar-adj .active .SvgIcon {
  background: #6259CA;
  color: #EAEDF7 !important;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  padding: 5px;
  margin-right: 10px;
}

.sidebar-adj .active .ListText {
  color: #6259CA !important;
}

.sidebar-adj .Navlink:hover {
  text-decoration: none;
}


.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 605px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 10px;
  background: white;
}

.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.MuiButton-outlinedPrimary {
  background: #DB0D0D;
  border-radius: 10px;
  color: #fff;
  border: 1px solid #DB0D0D;
}

.MuiButton-outlinedPrimary {
  color: #fff !important;
  background-color: #DB0D0D !important;
  border: 1px solid #DB0D0D !important;
  border-radius: '10px';
}

.MuiButton-outlinedPrimary:hover {
  background: #fff !important;
  color: #DB0D0D !important;
  border: 1px solid #DB0D0D !important;
  border-radius: '10px';
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.rti--container {
  max-width: 100%;
  --rti-bg: rgb(59, 236, 74);
  --rti-border: #ced4da;
  --rti-main: #756ecf;
  --rti-radius: 0.375rem;
  --rti-s: 0.5rem;
  /* spacing */
  --rti-tag: #EAEDF7;
  --rti-tag-remove: red;
}

.ReactTable .rt-thead .rt-th input, .ReactTable .rt-thead .rt-td input {
  text-align: left;
  padding-right: 18%;
  text-overflow: ellipsis;
  font-size: 11px;
}

@media(max-width:780px){
  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

}

@media(max-width:425px) {
  #sideHamburger {
    left: 0px !important;
  }

  #RightNav {
    justify-content: flex-start !important;
    padding-left: 15px !important;
  }
}

@media(max-width:959px) {
  .hideSidebar {
    display: none !important;
  }
}

/* .MuiBox-root .MuiBox-root-194 .makeStyles-buttonStyle-189
{
  display: flex;
  justify-content: center !important;
} */

#registerIcon {
  background: linear-gradient(60deg, #20C997, #1EDBA3) !important;
}

#bookingIcon {
  background: linear-gradient(60deg, #FF6DA7, #FFA5C9) !important;
}


@media(max-width:500px) {
  #TimmingSlot {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

@media(max-width:1260px) {

  #wrapText {
    margin-left: 0px !important;
    overflow: auto !important;
    padding-bottom: 10px !important;
  }
}

#wrapText {
  flex-wrap: nowrap !important;
}


.MuiRadio-colorSecondary.Mui-checked {
  color: #6259CA !important;
}

.MuiRadio-colorSecondary {
  color: #6259CA !important;
}

.appbarHistory+div>div>div {
  margin-top: 10px;
}


@media(max-width:600px) {
  #mainArea {
    padding-inline: 16px;
  }

  #AccountTop {
    margin-left: 15px;
    margin-right: 20px;
  }


  #marginInline {
    margin-inline: 15px;
  }
}


@media(max-width:450px) {
  #topResponse {
    margin-top: 30px;
  }
}


@media(max-width:360px) {
  #accountSetting {
    width: 50%;
  }
}

.makeStyles-drawerPaper-6 {
  width: 258px !important;
}

.removeInlinePad>div {
  padding-inline: 0 !important;
}


@media(max-width:500px) {
  #buttonIcon {
    padding: 0px !important;
  }
}

@media(max-width:599px) {
  #bookingStyles {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  #bookingText {
    padding-left: 0px !important;
  }
}

#bookingStyles{
  padding-left: 0px !important;
}

@media(max-width:767px) {
  #breadcrumName {
    padding-left: 15px !important;
  }
}

@media(max-width:768px) {
  #welcomeText {
    padding-left: 0px !important;
  }
}

#featured
{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#featuredListHeader
{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.MuiInputBase-input::placeholder{
  color:#9997B2;
  font-size:14px;
  font-family: Muli;
}

tr.MuiTableRow-root> td {
  padding-left: 0px;
}

#select-outlined
{
  background-color: #eaeaea !important;
}

div#boxSize > div {
  display: grid;
  height: 100%;
}

div#boxSize > div > div {
 align-self: center;
}

@media(max-width:1118px)
{
  div#boxSize label 
  {
    font-size: 15px !important;
  }
}

@media(max-width:1018px)
{
  div#boxSize label 
  {
    font-size: 12px !important;
  }
}
@media(max-width:959px)
{
  div#boxSize label 
  {
    font-size: 14px !important;
  }
}

@media(max-width:1118px)
{
  div#containerText  label 
  {
    font-size: 15px !important;
  }
}

@media(max-width:1018px)
{
  div#containerText label 
  {
    font-size: 12px !important;
  }
}
@media(max-width:959px)
{
  div#containerText  label 
  {
    font-size: 14px !important;
  }
}
